import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepFour extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link"
            linkTwoClass="aga-casestudy-link active"
            linkThreeClass="aga-casestudy-link"
          />         
          <CaseStudyStep
            title="Step #4"
            question="What treatment and management plan do you prescribe for Jennifer?"
            body={[
              <h3>Expert Opinion</h3>,            
              <p>Cross-sectional imaging is an important first step in confirming any abnormality that might be driving a patient like Jennifer’s continued symptoms. Either a CT scan or MRI could be useful in identifying these abnormalities prior to ordering a colonoscopy, with cost usually not a differentiating factor since both tests are relatively expensive. For a woman of child-bearing age like Jennifer, however, an MRI, if available and tolerable, would prevent unnecessary radiation exposure. While a single CT for diagnostic purposes does not result in a significant radiation exposure, the use of CT for monitoring disease activity can produce a substantial radiation burden over time, generally making MRI is the preferred modality.<sup>7</sup></p>,
              <p>The findings from the MRI together with Jennifer’s symptoms and the findings of her primary-care clinician suggest that she may have Crohn’s disease. While thickening of the bowel can be due to cancer, the location of this thickening (small bowel and not colon) suggests inflammation first before a cancer. However, Crohn’s disease cannot be diagnosed by imaging alone. A colonoscopy is required to obtain a tissue diagnosis of Crohn’s disease. Once a diagnosis has been made, Jennifer can be offered video capsule technology instead of a colonoscopy to follow up for evidence of healing and reduce the extent of prep she has to endure. While some prep will still be required, future prep can be a combination of MiraLax© and Gatorade© rather than prescription lavage.</p>,
              <p>Now that we have a diagnosis, it is time to determine appropriate therapy. According to the recently published  American College of Gastroenterology (ACG) Crohn’s guidelines, Jennifer has mild Crohn’s disease of the terminal ileum. It is mild because she has not had any complications of the disease like a fistula, abscess, or stricture. Nor has she been hospitalized or required systemic steroids to control symptoms. For mild disease like Jennifer’s, starting with a non-systemic steroid like budesonide that is formulated to deliver medication directly to the terminal ileum is appropriate.  A 5-ASA product is not appropriate here as there is no evidence that these anti-inflammatory products, well-established for use in ulcerative colitis, work in small bowel disease. </p>,
              <p>The budesonide is likely to provide short-term symptom relief so long as Jennifer’s disease is confined to the terminal ileum and right colon. However, if the disease continues to progress, Jennifer may need immunomodulation to control symptoms and achieve healing. I would therefore run a TPMT (thiopurine methyltransferase) phenotype assay to ensure that she has sufficient TPMT activity to metabolize a thiopurine (e.g., azathioprine, 6-mercaptopurine) that you might prescribe in the future. In addition, you might consider checking hepatitis antibody titers to assess immunity to hepatitis B and a test for TB (serum Quantiferon or skin PPD) if the disease continues to be symptomatic or progress despite the budesonide and a biologic is indicated. If there is no immunity to hepatitis B, then vaccination now makes sense, and if there is evidence of latent TB then treating with nine months of INH (isoniazid) therapy is warranted.<sup>8</sup></p>,
              <section className="aga-cs-container-short">
              <section className="aga-container-short">
              <h2 className="aga-cs-question-title">Progression</h2>
              <p>You prescribe budesonide 9 mg daily to Jennifer and tell her that if the treatment works she will have significantly fewer stools and no pain. You ask her to follow up with you in eight weeks to assess her progress. She returns to the GI in 8 weeks and says that she feels a bit better and does not need any more therapy for now.</p>
              </section>
              </section>,
              <p><sup>7</sup> Walsham NE, Sherwood RA. Fecal calprotectin in inflammatory bowel disease. Clin Exp Gastroenterol 2016; 9: 21–29. DOI: <a href="https://dx.doi.org/10.2147%2FCEG.S51902">10.2147/CEG.S51902</a></p>,
              <p><sup>8</sup> Lichtenstein GR, et al. ACG clinical guideline: Management of Crohn’s Disease in adults. Am J Gastroenterol 2018:113:481-517. DOI: <a href="https://doi.org/10.1038/ajg.2018.27">10.1038/ajg.2018.27;</a></p>,
            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/cases/medication-management-gastroenterology#part-2"
            backText="Back to Case Two"
            nextLink="/steps/step-5"
            nextText="Go to Step 5"
          />  
        </Layout>
      </div>
    )
  }
}
